import { POSTHOG_CAPTURE_TYPES } from "@/posthog/postHogConfig"
import { useCapturePostHogEvent } from "@/posthog/useCapturePostHogEvent"
import { FilterButton } from "@designSystem/buttons/FilterButton"
import { SwitchButton } from "@designSystem/buttons/SwitchButton"
import { Checkbox } from "@designSystem/inputs/Checkbox"
import { DateRangePicker } from "@designSystem/inputs/datePicker/DateRangePicker"
import { TextInput } from "@designSystem/inputs/TextInput"
import DropDown from "@designSystem/overlays/Dropdown/Dropdown"
import { DropDownListItem } from "@designSystem/overlays/Dropdown/DropdownListItem"
import { DropdownPanel } from "@designSystem/overlays/Dropdown/DropdownPanel"
import { Typography } from "@designSystem/typography/Typography"
import {
    AdjustmentsVerticalIcon,
    CalendarIcon,
    ChevronDownIcon,
    ListBulletIcon,
    MagnifyingGlassIcon,
    Squares2X2Icon,
} from "@heroicons/react/24/outline"
import { IDateRange } from "@utils/dateRangeUtils"
import React from "react"
import { useTranslation } from "react-i18next"
import {
    IDetectionsFilterOption,
    IDetectionsFilterSettings,
    IDetectionsSortDirection,
} from "../hooks/useDetectionsFilters"
import { ArrowDownIcon, ArrowUpIcon, XMarkIcon } from "@heroicons/react/24/solid"
import { localStorageSettings } from "@/localStorage/localStorage"

interface Props {
    searchValue: string
    setSearchValue: (value: string) => void
    sortBy: IDetectionsFilterOption
    setSortBy: (value: IDetectionsFilterOption) => void
    dateRange: IDateRange | undefined
    setSelectedDateRange: (range: IDateRange | undefined) => void
    hasCustomDateRange: string | undefined
    filterSettings: IDetectionsFilterSettings
    setFiltersetting: (value: IDetectionsFilterSettings) => void
    activeDisplay: "cards" | "tables"
    setActiveDisplay: (value: "cards" | "tables") => void
    sortDirection: IDetectionsSortDirection
    setSortDirection: (direction: IDetectionsSortDirection) => void
}

const SortDirectionIcon: React.FC<{ isActive: boolean; direction: IDetectionsSortDirection }> = ({
    isActive,
    direction,
}) => {
    if (isActive === false) {
        return null
    }

    if (direction === "ASC") {
        return <ArrowUpIcon width={14} height={14} />
    }

    return <ArrowDownIcon width={14} height={14} />
}

export const DetectionsFilterBar: React.FC<Props> = ({
    searchValue,
    setSearchValue,
    sortBy,
    setSortBy,
    dateRange,
    setSelectedDateRange,
    hasCustomDateRange,
    filterSettings,
    setFiltersetting,
    activeDisplay,
    setActiveDisplay,
    sortDirection,
    setSortDirection,
}) => {
    const { t } = useTranslation()
    const { captureEvent } = useCapturePostHogEvent()

    return (
        <div className="my-6 flex lg:items-center flex-col lg:flex-row gap-2  lg:justify-between w-full flex-wrap">
            <TextInput
                value={searchValue}
                onChange={(event) => {
                    setSearchValue(event.target.value)
                }}
                className="md:w-[325px] w-full"
                iconLeft={<MagnifyingGlassIcon width={20} />}
                iconRight={
                    <XMarkIcon
                        width={18}
                        className="text-text-secondary-light cursor-pointer"
                        onClick={() => {
                            setSearchValue("")
                        }}
                    />
                }
                onClick={() => {
                    captureEvent(POSTHOG_CAPTURE_TYPES.DETECTIONS.FILTERING.SEARCH_FILTER_CHANGED)
                }}
                placeholder={t("detections:filterBar.search")}
                data-testid="detections-search"
            />

            <div className="flex items-center gap-2 lg:gap-1 flex-wrap">
                {/* Sorting dropdown */}
                <DropDown
                    buttonType="div"
                    button={
                        <FilterButton data-testid="metrics-month-picker" className="w-[155px] h-[48px] ">
                            <div className="flex justify-between items-center w-full">
                                <div className="flex gap-1 items-center">
                                    <Typography variant="body-2">{t("detections:filterBar.sortBy")}</Typography>
                                    <Typography variant="body-2-semibold" className="capitalize">
                                        {t(`detections:filterBar.sorting.title`)}
                                    </Typography>
                                    <SortDirectionIcon isActive direction={sortDirection} />
                                </div>
                                <ChevronDownIcon height={16} width={16} className="text-brand-eye-blue" />
                            </div>
                        </FilterButton>
                    }
                >
                    {() => {
                        return (
                            <DropdownPanel>
                                <DropDownListItem
                                    onClick={() => {
                                        setSortDirection("ASC")
                                        setSortBy("triggered_at")
                                        captureEvent(POSTHOG_CAPTURE_TYPES.DETECTIONS.SORTING.ASCENDING)
                                    }}
                                >
                                    <div className="flex items-center gap-2 justify-between">
                                        <Typography
                                            variant={sortDirection === "ASC" ? "body-2-semibold" : "body-2"}
                                            color="inherit"
                                        >
                                            {t("detections:filterBar.sorting.ascending")}
                                        </Typography>
                                        <SortDirectionIcon isActive={sortBy === "triggered_at"} direction={"ASC"} />
                                    </div>
                                </DropDownListItem>
                                <DropDownListItem
                                    onClick={() => {
                                        setSortDirection("DESC")
                                        setSortBy("triggered_at")
                                        captureEvent(POSTHOG_CAPTURE_TYPES.DETECTIONS.SORTING.DESCENDING)
                                    }}
                                >
                                    <div className="flex items-center gap-2 justify-between">
                                        <Typography
                                            variant={sortDirection === "DESC" ? "body-2-semibold" : "body-2"}
                                            color="inherit"
                                        >
                                            {t("detections:filterBar.sorting.descending")}
                                        </Typography>
                                        <SortDirectionIcon isActive={sortBy === "triggered_at"} direction={"DESC"} />
                                    </div>
                                </DropDownListItem>

                                {/* <DropDownListItem
                                    onClick={() => {
                                        if (sortBy === "asset_identifier") {
                                            reverseSortingDirection()
                                        }

                                        setSortBy("asset_identifier")
                                    }}
                                >
                                    <div className="flex items-center gap-2 justify-between">
                                        {t("detections:filterBar.sorting.asset_identifier")}
                                        <SortDirectionIcon
                                            isActive={sortBy === "asset_identifier"}
                                            direction={sortDirection}
                                        />
                                    </div>
                                </DropDownListItem> */}
                            </DropdownPanel>
                        )
                    }}
                </DropDown>

                <DateRangePicker
                    range={dateRange}
                    display={{ dateInput: false, rangeOptions: true }}
                    onChange={setSelectedDateRange}
                    placeholder={t("dateRangePicker.placeholder", { ns: "common" })}
                    // If the fromTime & toTime are not defined, the default range is set to last30Days.
                    // Otherwise it's a custom range which the daterange picker can deal with.
                    initialRangeOption={hasCustomDateRange ? undefined : "last30Days"}
                    customRangeDisplay={
                        <FilterButton withBorder={false} tooltip={t("detections:filterBar.calendar")}>
                            <CalendarIcon height={20} width={20} className="text-brand-eye-blue" />
                        </FilterButton>
                    }
                />

                {/* Filtering Dropdown */}
                <DropDown
                    buttonType="div"
                    button={
                        <FilterButton tooltip={t("detections:filterBar.filters.title")}>
                            <AdjustmentsVerticalIcon height={20} width={20} className="text-brand-eye-blue" />
                        </FilterButton>
                    }
                >
                    {() => {
                        return (
                            <DropdownPanel>
                                <Checkbox
                                    label={t("detections:filterBar.filters.normalBehaviour")}
                                    initialValue={filterSettings.normalBehaviour}
                                    onCheck={(checked) => {
                                        captureEvent(
                                            POSTHOG_CAPTURE_TYPES.DETECTIONS.FILTERING.BEHAVIOUR_FILTER_CHANGED,
                                        )
                                        setFiltersetting({
                                            ...filterSettings,
                                            normalBehaviour: checked,
                                        })
                                    }}
                                />
                                <Checkbox
                                    label={t("detections:filterBar.filters.abnormalBehaviour")}
                                    initialValue={filterSettings.abnormalBehaviour}
                                    onCheck={(checked) => {
                                        captureEvent(
                                            POSTHOG_CAPTURE_TYPES.DETECTIONS.FILTERING.BEHAVIOUR_FILTER_CHANGED,
                                        )
                                        setFiltersetting({
                                            ...filterSettings,
                                            abnormalBehaviour: checked,
                                        })
                                    }}
                                />
                                <Checkbox
                                    label={t("detections:filterBar.filters.maliciousBehaviour")}
                                    initialValue={filterSettings.maliciousBehaviour}
                                    onCheck={(checked) => {
                                        captureEvent(
                                            POSTHOG_CAPTURE_TYPES.DETECTIONS.FILTERING.BEHAVIOUR_FILTER_CHANGED,
                                        )
                                        setFiltersetting({
                                            ...filterSettings,
                                            maliciousBehaviour: checked,
                                        })
                                    }}
                                />
                                {/* TODO: Enable the contacted filter once it's available in the BE */}
                                {/* <Checkbox
                                    label={t("detections:filterBar.filters.contacted")}
                                    initialValue={filterSettings.contacted}
                                    onCheck={(checked) => {
                                        setFiltersetting({
                                            ...filterSettings,
                                            contacted: checked,
                                        })
                                    }}
                                /> */}
                            </DropdownPanel>
                        )
                    }}
                </DropDown>

                <SwitchButton
                    activeButton={activeDisplay}
                    onChange={() => {
                        const modeToActivate = activeDisplay === "cards" ? "tables" : "cards"
                        captureEvent(
                            modeToActivate === "cards"
                                ? POSTHOG_CAPTURE_TYPES.DETECTIONS.CARD_VIEW_ACTIVATED
                                : POSTHOG_CAPTURE_TYPES.DETECTIONS.TABLE_VIEW_ACTIVATED,
                        )

                        const nameToUpdate = modeToActivate as "cards" | "tables"

                        setActiveDisplay(nameToUpdate)
                        localStorageSettings.setActiveDetectionsDisplay(nameToUpdate)
                    }}
                    buttons={[
                        {
                            name: "cards",
                            icon: <Squares2X2Icon height={20} width={20} className="text-brand-eye-blue" />,
                            tooltip: t("detections:filterBar.cardView"),
                        },
                        {
                            name: "tables",
                            icon: <ListBulletIcon height={20} width={20} className="text-brand-eye-blue" />,
                            tooltip: t("detections:filterBar.tableView"),
                        },
                    ]}
                />
            </div>
        </div>
    )
}
