import { useTranslation } from "react-i18next"
import React, { Fragment } from "react"
import { Alert } from "../components/designSystem/feedback/notifications/Alert"
import { DetectionSkeleton } from "../components/designSystem/feedback/skeletons/DetectionSkeleton"
import { PageHeader } from "../components/header/PageHeader"
import { DetectionsTable } from "../features/detections/components/DetectionsTable"
import { useDetections } from "../features/detections/hooks/useDetections"
import { PageLayout } from "../components/layout/PageLayout"
import { Typography } from "../components/designSystem/typography/Typography"
import { NoDataMessage } from "../components/designSystem/feedback/notifications/NoDataMessage"
import { useUrlSyncingDateRangePickerState } from "@designSystem/inputs/datePicker/useDateRangePickerState"
import { DateRangePicker } from "@designSystem/inputs/datePicker/DateRangePicker"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { getValidDateRange } from "@utils/dateRangeUtils"
import { useGetAllSearchParams } from "@hooks/useGetAllSearchParams"
import { TimeAgoDate } from "@components/dates/TimeAgoDate"
import AwaitingDataIllustration from "@assets/illustrations/awaitingDataIllustration.svg?react"
import { DetectionsV2Page } from "./DetectionsV2Page"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { featureFlagConfig } from "@config/featureFlagConfig"

export const Detections: React.FC = () => {
    const { t } = useTranslation(["pages", "common"])
    const activeEyed = useActiveEyed()
    const { dateRange, setSelectedDateRange } = useUrlSyncingDateRangePickerState()
    const { fromTime, toTime } = useGetAllSearchParams()
    const hasCustomDateRange = fromTime && toTime
    const detectionsV2Enabled = useFeatureFlagEnabled(featureFlagConfig.detectionsV2)

    // Everytime the dateRange changes, we make a validated copy which gets send to the api.
    // This has the following corrected:
    // 1. The from to order is set chronologically
    // 2. The from and to have their startOfDay and endOfDay set
    // 3. The range is stripped of of future dates.
    const validatedRange = React.useMemo(() => {
        return getValidDateRange({ from: dateRange?.from, to: dateRange?.to })
    }, [dateRange])

    const { isError, error, isPending, data, detectionsSummary, isSuccess } = useDetections(activeEyed, validatedRange)

    const numberOfDetections = data?.data?.length ?? 0

    // The detectionId in the search params indicates that the user navigated to this page from the widget
    // In this case the detectionId should be passed to the table to indicated a selected row
    const { detectionId } = useGetAllSearchParams()

    if (detectionsV2Enabled) {
        return <DetectionsV2Page />
    }

    return (
        <PageLayout variant="detailPage">
            <PageHeader
                title={t("detections.title")}
                subtitle={
                    <div className="flex gap-2 items-center flex-wrap">
                        <Typography variant="body-1-semibold">
                            {t("detections.widgetSubtitle", { count: numberOfDetections })}
                        </Typography>

                        {data?.meta_data?.last_updated && (
                            <Fragment>
                                <div className="h-3 w-[2px] bg-neutral-100" />

                                <TimeAgoDate
                                    prefix={t("details.timestamp_updated")}
                                    timestamp={data?.meta_data.last_updated}
                                    testId="detections-updated-since"
                                />
                            </Fragment>
                        )}
                    </div>
                }
                withBreadcrumbs
            />

            <div>
                <div className="flex justify-between items-start lg:items-center mb-6 flex-col lg:flex-row">
                    {/* <Typography variant="header-2">{t("pages:detections.tableHeader")}</Typography> */}
                    <div className="mt-2 lg:mt-0">
                        <DateRangePicker
                            range={dateRange}
                            onChange={setSelectedDateRange}
                            placeholder={t("dateRangePicker.placeholder", { ns: "common" })}
                            // If the fromTime & toTime are not defined, the default range is set to last30Days.
                            // Otherwise it's a custom range which the daterange picker can deal with.
                            initialRangeOption={hasCustomDateRange ? undefined : "last30Days"}
                        />
                    </div>
                </div>

                {isPending ? (
                    <DetectionSkeleton />
                ) : (
                    <div>
                        {isError && <Alert type="error" title="Something went wrong" text={error?.message} />}

                        {isSuccess && detectionsSummary.length > 0 && (
                            <DetectionsTable data={data?.data ?? []} selectedDetectionId={detectionId} />
                        )}

                        {isSuccess && detectionsSummary.length === 0 && (
                            <NoDataMessage
                                title={t("detections.messages.noDataTitle")}
                                subtitle={t("detections.messages.noDataSubtitle")}
                                variant="row"
                                image={<AwaitingDataIllustration className="h-[250px]" />}
                            />
                        )}
                    </div>
                )}
            </div>
        </PageLayout>
    )
}
