import { localStorageSettings } from "@/localStorage/localStorage"
import { NewLabel } from "@components/newLabel/NewLabel"
import { featureFlagConfig } from "@config/featureFlagConfig"
import { useFeatureFlagEnabled } from "posthog-js/react"
import React from "react"

export const DetectionsNavigationLabel: React.FC = () => {
    // The inverse of has visited, if the user has visited detections we don't want to show the is new label
    const showLabel = !localStorageSettings.getHasVisitedDetections()
    const detectionsV2Enabled = useFeatureFlagEnabled(featureFlagConfig.detectionsV2)

    if (showLabel && detectionsV2Enabled) {
        return <NewLabel />
    }
}
