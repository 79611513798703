import { PostHogConfig } from "posthog-js"

export const POSTHOG_CONFIGURATION: Partial<PostHogConfig> = {
    api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
    autocapture: true,
    capture_pageview: false,
    capture_pageleave: false,
    persistence: "memory", // Stores in page memory, which means data is only persisted for the duration of the page view.
    mask_all_text: true,
    debug: false,
}

export const PREVENT_POSTHOG_CAPTURE_EVENTS_CLASS = "ph-no-capture"

// Tags & Texts used by the PostHogStatus component -> which is used to identify the posthog status for playwright tests and manual testing
export const POSTHOG_STATUS_TAG = "posthog-status"
export const POSTHOG_STATUS_CONSENT = "ANALYTICS CONSENT GIVEN"
export const POSTHOG_STATUS_NON_CONSENT = "ANALYTICS CONSENT REJECTED"
export const POSTHOG_STATUS_REQUESTING_CONSENT = "REQUESTING CONSENT"

export const POSTHOG_CAPTURE_TYPES = {
    SET_PROPERTIES_EVENT: "SET_USER_PROPERTIES",
    DOWNLOAD_RECOMMENDATION_CSV_EVENT: "DOWNLOAD_RECOMMENDATION_CSV",
    EAST: {
        INSTALL: "PRESSED_EAST_INSTALL_BUTTON",
        CLOSE: "PRESSED_EAST_CLOSE_BUTTON",
    },
    AWARENESS: {
        TOGGLE_RECIPIENT_EVENT: "TOGGLE_AWARENESS_RECIPIENT",
    },
    THREAT_HUNTING: {
        ACKNOWLEDGED_THREAT_HUNT: "THREAT_HUNT_ACKNOWLEDGED",
    },
    MANAGEMENT_REPORTING: {
        DOWNLOAD_CURRENT_REPORT: "DOWNLOAD_CURRENT_REPORT",
        DOWNLOAD_MONTHLY_REPORT: "DOWNLOAD_MONTHLY_REPORT",
    },
    DETECTIONS: {
        CARD_VIEW_ACTIVATED: "DETECTIONS_CARD_VIEW_ACTIVATED",
        TABLE_VIEW_ACTIVATED: "DETECTIONS_TABLE_VIEW_ACTIVATED",
        OPENED_DETAILS: "DETECTIONS_OPENED_DETAILS",
        SORTING: {
            ASCENDING: "DETECTIONS_SORTING_SET_ASCENDING",
            DESCENDING: "DETECTIONS_SORTING_SET_DESCENDING",
        },
        FILTERING: {
            BEHAVIOUR_FILTER_CHANGED: "DETECTIONS_CHANGED_BEHAVIOUR_FILTER",
            SEARCH_FILTER_CHANGED: "DETECTIONS_SEARCH_FILTER_CHANGED",
        },
    },
    INSURANCE_UPSELL: {
        REQUEST_QUOTE: "INSURANCE_UPSELL_REQUESTED_QUOTE",
        REQUEST_QUOTE_EDR_PAGE: "INSURANCE_UPSELL_REQUESTED_QUOTE_EDR_PAGE",
        TOOLTIP: {
            SKIP: "INSURANCE_UPSELL_TOOLTIP_SKIPPED",
        },
        QUIZ: {
            ANSWERED: "INSURANCE_UPSELL_QUIZ_ANSWERED_QUESTION",
            SKIP: "INSURANCE_UPSELL_QUIZ_SKIPPED",
        },
    },
}

// Posthog grouping types
export const ORGANISATION_GROUP_TYPE = "organisation"
