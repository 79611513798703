import { BarChartSkeleton } from "@designSystem/feedback/skeletons/BarChartSkeleton"
import { Typography } from "@designSystem/typography/Typography"
import { useDetectionsV2Statistics } from "@features/detectionsv2/hooks/useDetectionsV2Statistics"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { formatYearMonthToTinyMonth } from "@utils/dateUtils"
import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { Bar, BarChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis } from "recharts"
import EyeServersIllustration from "@assets/illustrations/eyeServersIllustration.svg?react"

const CustomTooltip = ({ active, payload }: TooltipProps<string, string>) => {
    const { t } = useTranslation()

    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip bg-brand-eye-blue border rounded-md px-4 py-2 w-[150px]">
                {payload.map((dataSet) => {
                    return (
                        <div className="flex items-center justify-between" key={dataSet.name}>
                            <div className="flex items-center gap-2">
                                <div className="w-[12px] h-[12px] rounded-full" style={{ background: dataSet.color }} />

                                <Typography color="text-invert" variant="body-2">
                                    {`${dataSet.value} ${t("detections:historicGraphWidget.label")}`}
                                </Typography>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return null
}

export const HistoricalDetectionsWidget: React.FC<{ isLoading?: boolean }> = ({ isLoading }) => {
    const activeEyed = useActiveEyed()
    const { t } = useTranslation()
    const { data: statisticsData } = useDetectionsV2Statistics(activeEyed)
    const lastMonthsData = statisticsData?.data?.detections_last_12_months ?? []
    // Count the number of months which have more than 1 detection available.
    const monthsWithHistoricData =
        statisticsData?.data?.detections_last_12_months.filter((month) => month.count > 0).length ?? 0

    // Depending on the amount of months with historic data show the events graph.
    const showGraph = monthsWithHistoricData >= 4

    const graphData = lastMonthsData.map((dataPoint) => {
        return {
            label: formatYearMonthToTinyMonth(dataPoint.period),
            ...dataPoint,
        }
    })

    if (isLoading) {
        return (
            <div className="card flex-1 h-[250px] px-6 py-4">
                <BarChartSkeleton />
            </div>
        )
    }

    return (
        <div className="card block w-full lg:flex-1 h-[275px] p-4">
            {showGraph === true && (
                <Fragment>
                    <div>
                        <Typography variant="body-1-semibold">{t("detections:historicGraphWidget.title")}</Typography>
                        <Typography variant="body-3" color="text-secondary">
                            {t("detections:historicGraphWidget.overall")}
                        </Typography>
                    </div>
                    <ResponsiveContainer width={"100%"} height={200}>
                        <BarChart data={graphData}>
                            <XAxis dataKey="label" axisLine={false} tickLine={false} />
                            <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />
                            <Bar dataKey="count" fill="#B2D3EB" radius={[3, 3, 0, 0]} barSize={16} />
                        </BarChart>
                    </ResponsiveContainer>
                </Fragment>
            )}

            {showGraph === false && (
                <div className="w-full h-full flex justify-center items-center">
                    <div className="space-y-4 opacity-50">
                        <EyeServersIllustration className="w-[230px] h-[150px]" />
                        <Typography variant="header-5">{t("detections:historicGraphWidget.collecting")}</Typography>
                    </div>
                </div>
            )}
        </div>
    )
}
