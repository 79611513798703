import { Typography } from "@designSystem/typography/Typography"
import { IDetectionV2 } from "../types"
import { Button } from "@designSystem/buttons/Button"
import { ResolutionStatusIcon } from "./ResolutionStatusIcon"
import { getResolutionStatusText } from "@utils/detectionUtils"
import { useTranslation } from "react-i18next"
import { formatDate } from "date-fns"
import { DATE_FORMATS } from "@utils/dateUtils"

interface Props {
    detection: IDetectionV2
    isExpanded?: boolean
    isSelected?: boolean
    isCardClickable?: boolean
    onViewDetails?: (detection: IDetectionV2) => void
}

export const DetectionCard: React.FC<Props> = ({
    detection,
    isExpanded = false,
    onViewDetails,
    isSelected = false,
    isCardClickable = false,
}) => {
    const { t } = useTranslation()
    const resulutionStatusDisplay = getResolutionStatusText(detection.resolution_status ?? "")

    return (
        <div
            className={`card ${isSelected ? "border-text-link-light" : ""} ${isCardClickable ? "cursor-pointer" : ""}`}
            onClick={
                isCardClickable && onViewDetails
                    ? () => {
                          onViewDetails(detection)
                      }
                    : undefined
            }
        >
            <div className="flex justify-between p-4 items-center flex-row">
                <div className="flex items-center gap-4">
                    <ResolutionStatusIcon status={detection.resolution_status} />

                    <div>
                        <Typography variant="body-1-semibold" className="capitalize">
                            {detection.category.replaceAll("_", " ")}
                        </Typography>
                        <div className="flex md:divide-x *:px-1 -ml-1 flex-col md:flex-row">
                            {detection.triggered_at && (
                                <Typography variant="body-3">
                                    {formatDate(detection.triggered_at, DATE_FORMATS.DATE_TIME_FORMAT)}
                                </Typography>
                            )}
                            <Typography variant="body-3">{detection.asset_identifier}</Typography>
                        </div>
                    </div>
                </div>

                {/* This renders the view details button on large screens ( top right of the card ) */}
                {onViewDetails && (
                    <div className="hidden md:block">
                        <Button
                            variant="text"
                            size="small"
                            className="text-text-link-light"
                            onClick={() => {
                                onViewDetails(detection)
                            }}
                        >
                            {t("detections:cards.viewDetails")}
                        </Button>
                    </div>
                )}
            </div>

            {isExpanded && (
                <div className="border-t p-4">
                    <ul className="list-disc pl-6 marker:text-text-secondary-light space-y-[1px]">
                        <li>
                            <Typography variant="body-3" color="text-secondary">
                                {resulutionStatusDisplay.label}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body-3" color="text-secondary">
                                {t("detections:cards.detectedBy", { service: detection.service })}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body-3" color="text-secondary">
                                {detection.contacted
                                    ? t("detections:cards.contactRequired")
                                    : t("detections:cards.noContactRequired")}
                            </Typography>
                        </li>
                    </ul>
                </div>
            )}

            {/* This renders the view details button on small screens ( below the expanded section) */}
            {onViewDetails && (
                <div className="flex justify-end border-t md:hidden">
                    <Button
                        variant="text"
                        size="small"
                        className="text-text-link-light"
                        onClick={() => {
                            onViewDetails(detection)
                        }}
                    >
                        {t("detections:cards.viewDetails")}
                    </Button>
                </div>
            )}
        </div>
    )
}
