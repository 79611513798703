import { createColumnHelper } from "@tanstack/react-table"
import { Typography } from "@designSystem/typography/Typography"
import i18n from "@/i18next"
import { HoverableDate } from "@components/dates/HoverableDate"
import Tippy from "@tippyjs/react"
import { DATE_FORMATS } from "@utils/dateUtils"
import { IDetectionV2 } from "./types"
import { ResolutionStatusIcon } from "./components/ResolutionStatusIcon"
import { getResolutionStatusText } from "@utils/detectionUtils"

export const getDetectionV2Columns = () => {
    const columnHelper = createColumnHelper<IDetectionV2>()

    return [
        columnHelper.accessor("resolution_status", {
            size: 125,
            header: i18n.t("pages:detections.table.resolutionStatus"),
            cell: (info) => {
                const resolutionStatusText = getResolutionStatusText(info.getValue() ?? "").labelShort
                return (
                    <div className="flex items-center gap-2">
                        <ResolutionStatusIcon status={info.getValue()} />
                        <Typography variant="body-2" color="text-secondary">
                            {resolutionStatusText}
                        </Typography>
                    </div>
                )
            },
        }),
        columnHelper.accessor("category", {
            size: 300,
            header: i18n.t("pages:detections.table.category"),
            cell: (info) => (
                <Tippy content={info.getValue()} placement="top-start" arrow={false}>
                    <Typography shouldPreventWrapping textElipsis className="capitalize" variant="body-2">
                        {info.getValue().replaceAll("_", " ")}
                    </Typography>
                </Tippy>
            ),
        }),
        columnHelper.accessor("asset_identifier", {
            size: 225,
            header: i18n.t("pages:detections.table.assetIdentifier"),
            cell: (info) => (
                <Typography shouldPreventWrapping textElipsis variant="body-2" color="text-secondary">
                    {info.getValue()}
                </Typography>
            ),
            enableHiding: true,
        }),
        columnHelper.accessor("service", {
            size: 150,
            header: i18n.t("pages:detections.table.source"),
            cell: (info) => (
                <Typography variant="body-2" className="capitalize" color="text-secondary">
                    {info.getValue()}
                </Typography>
            ),
            enableHiding: true,
        }),
        columnHelper.accessor("triggered_at", {
            size: 100,
            header: i18n.t("pages:detections.table.triggeredAt"),
            cell: (info) => <HoverableDate timestamp={info.getValue()} format={DATE_FORMATS.DATE_FORMAT} />,
            enableHiding: true,
        }),
    ]
}
