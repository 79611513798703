import { Typography } from "@designSystem/typography/Typography"
import React from "react"
import classNames from "classnames"
import Tippy from "@tippyjs/react"
import { getResolutionStatusText } from "@utils/detectionUtils"

interface Props {
    value: string
}

export const ConclusionBadge: React.FC<Props> = ({ value }) => {
    const status = getResolutionStatusText(value)

    return (
        <Tippy content={status.description} placement={"top"}>
            <div
                className={classNames(
                    {
                        "bg-background-grey-light text-text-secondary-light": status.label === "Normal behaviour",
                    },
                    { "bg-background-abnormal-light text-text-abnormal-light": status.label === "Abnormal behaviour" },
                    { "bg-background-critical-light text-text-critical-light": status.label === "Malicious behaviour" },
                    "inline-flex py-1 px-3 items-center justify-center rounded-md",
                )}
            >
                <Typography variant="body-2-semibold" color="inherit" shouldPreventWrapping>
                    {status.label}
                </Typography>
            </div>
        </Tippy>
    )
}
