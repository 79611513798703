import { buildThemedTextColorClass } from "@/styles/variables/designTokenUtils"
import classNames from "classnames"
import React, { PropsWithChildren, forwardRef } from "react"

type TypographyVariant =
    | "display-1"
    | "display-2"
    | "header-1"
    | "header-1-light"
    | "header-2"
    | "header-3"
    | "header-4"
    | "header-5"
    | "body-1"
    | "body-1-semibold"
    | "body-2"
    | "body-2-semibold"
    | "body-3"
    | "body-3-semibold"
    | "span"

export type TypographyColor =
    | "text-primary"
    | "text-secondary"
    | "text-inactive"
    | "text-invert"
    | "text-link"
    | "text-critical"
    | "text-warning"
    | "text-success"
    | "text-purple"
    | "text-abnormal"
    | "text-brand-eye-ice"
    | "inherit"

const typographyTag: Record<TypographyVariant, string> = {
    ["display-1"]: "h1",
    ["display-2"]: "h1",
    ["header-1"]: "h1",
    ["header-1-light"]: "h1",
    ["header-2"]: "h2",
    ["header-3"]: "h3",
    ["header-4"]: "h4",
    ["header-5"]: "h5",
    ["body-1"]: "p",
    ["body-1-semibold"]: "p",
    ["body-2"]: "p",
    ["body-2-semibold"]: "p",
    ["body-3"]: "p",
    ["body-3-semibold"]: "p",
    ["span"]: "span",
}

const topSpacingClass: Record<TypographyVariant, string> = {
    ["display-1"]: "mt-8 sm:mt-12",
    ["display-2"]: "mt-8 sm:mt-12",
    ["header-1"]: "mt-8 sm:mt-12",
    ["header-1-light"]: "mt-8 sm:mt-12",
    ["header-2"]: "mt-8 sm:mt-12",
    ["header-3"]: "mt-6 sm:mt-8",
    ["header-4"]: "mt-6 sm:mt-6",
    ["header-5"]: "mt-4 sm:mt-4",
    ["body-1"]: "sm:mt-6",
    ["body-1-semibold"]: "sm:mt-6",
    ["body-2"]: "sm:mt-6",
    ["body-2-semibold"]: "sm:mt-6",
    ["body-3"]: "sm:mt-6",
    ["body-3-semibold"]: "sm:mt-6",
    ["span"]: "sm:mt-6",
}

const bottomSpacingClass: Record<TypographyVariant, string> = {
    ["display-1"]: "mb-4",
    ["display-2"]: "mb-4",
    ["header-1"]: "mb-4",
    ["header-1-light"]: "mb-4",
    ["header-2"]: "mb-4",
    ["header-3"]: "mb-2",
    ["header-4"]: "mb-2",
    ["header-5"]: "mb-2",
    ["body-1"]: "mb-6",
    ["body-1-semibold"]: "mb-6",
    ["body-2"]: "mb-6",
    ["body-2-semibold"]: "mb-6",
    ["body-3"]: "mb-6",
    ["body-3-semibold"]: "mb-6",
    ["span"]: "mb-6",
}

export interface TypographyProps {
    variant?: TypographyVariant
    color?: TypographyColor
    spacingTop?: boolean
    spacingBottom?: boolean
    shouldPreserveNewLines?: boolean
    textElipsis?: boolean
    className?: string
    shouldPreventWrapping?: boolean
    withUnderline?: boolean
    withTextWrapping?: boolean
    testId?: string
}

export const Typography: React.FC<PropsWithChildren<TypographyProps>> = forwardRef<
    HTMLElement,
    PropsWithChildren<TypographyProps>
>(
    (
        {
            variant = "body-1",
            color = "text-primary",
            spacingBottom,
            spacingTop,
            shouldPreserveNewLines,
            shouldPreventWrapping,
            className,
            testId,
            textElipsis,
            withTextWrapping,
            withUnderline,
            children,
            ...rest
        },
        ref,
    ) => {
        const themedColor = color === "inherit" ? "text-inherit" : buildThemedTextColorClass(color)

        const classes = classNames([
            variant,
            themedColor,
            { [topSpacingClass[variant]]: spacingTop },
            { [bottomSpacingClass[variant]]: spacingBottom },
            { "text-ellipsis w-full overflow-hidden text-left": textElipsis },
            { "whitespace-pre-line": shouldPreserveNewLines },
            { "whitespace-nowrap": shouldPreventWrapping },
            { "hover:underline inline-block hover:cursor-pointer": withUnderline },
            { "max-w-[800px]": withTextWrapping },
            className,
        ])

        const Tag = `${typographyTag[variant]}` as keyof JSX.IntrinsicElements

        return (
            //@ts-ignore ( VSCode can't make sense of the type with the additional type. We can safely ignore this )
            <Tag className={`${classes}`} ref={ref} data-testid={testId} {...rest}>
                {children}
            </Tag>
        )
    },
)

Typography.displayName = "Typography"
