import React from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import { useTranslation } from "react-i18next"
import { DOTS, PageOption } from "@utils/paginationUtils"
import classNames from "classnames"
import { Typography } from "@designSystem/typography/Typography"

interface Props {
    currentPage: number
    numberOfPages: number
    pagination: Array<string | number>
    pageSize: PageOption
    dataSize: number
    showPageText?: boolean

    goToNextPage: () => void
    goToPreviousPage: () => void
    goToSpecificPage: (page: number) => void
}

export const Pagination: React.FC<Props> = ({
    currentPage,
    numberOfPages,
    goToNextPage,
    dataSize,
    goToPreviousPage,
    goToSpecificPage,
    pagination,
    pageSize,
    showPageText = true,
}) => {
    const { t } = useTranslation(["common"])
    if (pageSize === "ALL") {
        return null
    }

    if (numberOfPages <= 1) {
        return null
    }

    const start = (currentPage - 1) * pageSize + 1
    const end = currentPage * pageSize

    return (
        <div className="flex items-center space-x-4">
            {showPageText && (
                <div className="hidden xl:block">
                    <Typography color="text-secondary">
                        {t("common:pagination.showingPerPage", {
                            start,
                            end: end > dataSize ? dataSize : end,
                            total: dataSize,
                        })}
                    </Typography>
                </div>
            )}

            <nav className="isolate inline-flex -space-x-px rounded-md" aria-label="Pagination">
                <button
                    className="relative inline-flex bg-white items-center rounded-l-md px-2 py-2 text-text-secondary-light ring-1 ring-inset ring-neutral-300 hover:bg-neutral-100 focus:z-20 focus:outline-offset-0"
                    onClick={goToPreviousPage}
                    disabled={currentPage === 1}
                >
                    <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
                </button>

                {pagination.map((page, index) => {
                    return (
                        <button
                            key={`${page}-${index}`}
                            onClick={() => {
                                // Specifically only navigate to numbered pages.
                                if (typeof page === "number") {
                                    goToSpecificPage(page)
                                }
                            }}
                            className={classNames(
                                {
                                    "bg-button-primary-background-disabled-light ring-brand-eye-orange hover:bg-orange-800 text-text-primary":
                                        page === currentPage,
                                },
                                { "bg-white": page !== currentPage },
                                { "cursor-auto": page === DOTS },
                                { "hover:bg-gray-50": page !== currentPage },
                                "w-[44px] relative text-center justify-center items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-neutral-300  focus:z-20 focus:outline-offset-0 hidden lg:inline-flex",
                            )}
                        >
                            {page}
                        </button>
                    )
                })}

                <button
                    onClick={goToNextPage}
                    disabled={currentPage === numberOfPages}
                    className="relative inline-flex items-center bg-white rounded-r-md px-2 py-2 text-text-secondary-light ring-1 ring-inset ring-neutral-300 hover:bg-neutral-100 focus:z-20 focus:outline-offset-0"
                >
                    <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                </button>
            </nav>
        </div>
    )
}
