import React, { useEffect, useState } from "react"
import Modal from "@designSystem/overlays/modal/Modal"
import { localStorageSettings } from "@/localStorage/localStorage"
import { Typography } from "@designSystem/typography/Typography"
import { useTranslation } from "react-i18next"
import { Button } from "@designSystem/buttons/Button"
import HelpdeskIllustration from "@assets/illustrations/helpdeskIllustration.svg?react"

export const DetectionsIntroModal: React.FC = () => {
    const [showIntroductionModal, setShowIntroductionModal] = useState(!localStorageSettings.getHasVisitedDetections())
    const { t } = useTranslation()

    // On mount, make sure the detections v2 visited property is set.
    useEffect(() => {
        localStorageSettings.setHasVisitedDetections()
    }, [])

    if (!showIntroductionModal) {
        return null
    }

    return (
        <Modal open={showIntroductionModal} setOpen={setShowIntroductionModal} className="p-10" withCloseButton>
            <div className="w-[400px]">
                <div className="flex justify-center mb-4">
                    <HelpdeskIllustration />
                </div>

                <div className="mt-6">
                    <Typography variant="header-2">{t("detections:modal.title")}</Typography>
                    <Typography className="mt-4">{t("detections:modal.description")}</Typography>
                    <Button
                        variant="primary"
                        className="mt-6"
                        size="small"
                        onClick={() => {
                            setShowIntroductionModal(false)
                        }}
                    >
                        {t("detections:modal.button")}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
