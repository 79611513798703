import { HeaderContext } from "@tanstack/react-table"
import { ArrowsUpDownIcon, ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/solid"
import { Typography } from "@designSystem/typography/Typography"
import { PropsWithChildren } from "react"

interface Props<T> extends HeaderContext<T, unknown> {
    text?: string
}

export function SortableTableHeader<T>(props: PropsWithChildren<Props<T>>): JSX.Element {
    const { header, children } = props

    return (
        <div className="flex items-center">
            <Typography variant="body-2-semibold" color="text-secondary">
                {children}
            </Typography>
            <div className="ml-1 text-neutral-100">
                {header.column.getIsSorted() === false ? (
                    <ArrowsUpDownIcon width={20} height={20} />
                ) : header.column.getIsSorted() === "asc" ? (
                    <ArrowDownIcon width={16} height={16} className="text-brand-eye-blue" />
                ) : (
                    <ArrowUpIcon width={16} height={16} className="text-brand-eye-blue" />
                )}
            </div>
        </div>
    )
}
