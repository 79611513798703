import { SortableTableHeader } from "@designSystem/layout/table/SortableTableHeader"
import {
    SortableTableHeaderCell,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
} from "@designSystem/layout/table/Table"
import { TablePaginationWrapper } from "@designSystem/layout/table/TablePaginationWrapper"
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { IThreatHunt, IThreatHuntWithAssets } from "../threatHuntingTypes"
import i18n from "@/i18next"
import { Typography } from "@designSystem/typography/Typography"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { useNavigate } from "react-router-dom"
import { useActiveEyed } from "@hooks/useActiveEyed"
import React from "react"
import { CopyHuntLinkTableAction } from "./CopyHuntLinkTableAction"
import { ThreatHuntInsuranceLabel } from "./ThreatHuntInsuranceLabel"
import { DATE_FORMATS, formatDate } from "@utils/dateUtils"
import { getAcknowledgeStatusText, getAcknowledgeStatusTooltip } from "@utils/threatHuntingUtils"
import Tippy from "@tippyjs/react"

interface Props {
    threatHunts: Array<IThreatHunt | IThreatHuntWithAssets>
    type: "REQUIRE_ATTENTION_TABLE" | "OTHER_HUNTS_TABLE"
}

const columnHelper = createColumnHelper<IThreatHunt | IThreatHuntWithAssets>()

const tableColumns = [
    columnHelper.accessor("name", {
        header: i18n.t("threatHunting:table.columns.name"),
        size: 300,
        cell: (info) => (
            <Typography textElipsis shouldPreventWrapping>
                {info.getValue()}
            </Typography>
        ),
    }),
    columnHelper.accessor("acknowledged", {
        header: i18n.t("threatHunting:table.columns.status"),
        size: 300,
        cell: (info) => {
            const tableType = (info.table?.options?.meta as { type?: Props["type"] }) ?? {
                type: "REQUIRE_ATTENTION_TABLE",
            }

            if (tableType.type === "REQUIRE_ATTENTION_TABLE") {
                return (
                    <Tippy
                        content={getAcknowledgeStatusTooltip(info.row.original)}
                        placement="left-start"
                        className="w-[300px]"
                    >
                        <div className="flex items-center gap-4">
                            <Typography
                                variant="body-1-semibold"
                                color={info.row.original.acknowledged === true ? "text-inactive" : "text-primary"}
                            >
                                {getAcknowledgeStatusText(info.row.original)}
                            </Typography>

                            <ThreatHuntInsuranceLabel threatHunt={info.row.original} />
                        </div>
                    </Tippy>
                )
            }

            return (
                <Tippy
                    content={getAcknowledgeStatusTooltip(info.row.original)}
                    placement="left-start"
                    className="w-[300px]"
                >
                    <Typography textElipsis shouldPreventWrapping color="text-inactive">
                        {getAcknowledgeStatusText(info.row.original)}
                    </Typography>
                </Tippy>
            )
        },
    }),
    columnHelper.accessor("startDate", {
        header: i18n.t("threatHunting:table.columns.reported"),
        cell: (info) => (
            <Typography textElipsis shouldPreventWrapping>
                {formatDate(info.getValue(), DATE_FORMATS.DATE_FORMAT)}
            </Typography>
        ),
    }),
    columnHelper.display({
        id: "actions",
        cell: (info) => {
            return <CopyHuntLinkTableAction uuid={info.row.original.threat_intel_id} />
        },
    }),
]

export const ThreatHuntTable: React.FC<Props> = ({ threatHunts, type }) => {
    const routerLinks = getRouterLinks()
    const navigate = useNavigate()
    const activeEyed = useActiveEyed()

    const table = useReactTable({
        data: threatHunts,
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        meta: {
            type,
        },
    })

    const navigateToDetailPage = (id: string): void => {
        navigate(routerLinks.threatHunting.detail({ id, eyed: activeEyed }))
    }

    return (
        <TablePaginationWrapper isRounded data={threatHunts} table={table}>
            <Table isRounded>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <SortableTableHeaderCell key={header.id} width={header.getSize()}>
                                        <div
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer select-none"
                                        >
                                            <SortableTableHeader {...header.getContext()}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </SortableTableHeader>
                                        </div>
                                    </SortableTableHeaderCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRow
                                key={row.id}
                                onClick={() => {
                                    navigateToDetailPage(row.original.threat_intel_id)
                                }}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id} width={cell.column.getSize()}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TablePaginationWrapper>
    )
}
