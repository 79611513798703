import { IAwarenessEvent } from "@features/awareness/awarenessTypes"
import { IDetectionsFilterOption, IDetectionsFilterSettings } from "@features/detectionsv2/hooks/useDetectionsFilters"
import { buildURLWithQueryParams } from "@utils/apiUtils"
import { DATE_FORMATS, formatDate } from "@utils/dateUtils"
import { translateFilterSettingsToResolutionStatusTags } from "@utils/detectionUtils"

/**
 *
 * Query Endpoints
 *
 */

interface QueryEndpoint {
    url: string
    queryKey: readonly unknown[]
}

export const getDetectionsEndpoint = (eyed: string, from?: string, to?: string): QueryEndpoint => {
    // To not constantly trigger new fetches ( because of the date range that's being passed, we create caching keys per hour.)
    const fromCachingKey = from ? `${formatDate(from, DATE_FORMATS.DATE_TIME_FORMAT_DETECTIONS_CACHE)}h` : undefined
    const toCachingKey = to ? `${formatDate(to, DATE_FORMATS.DATE_TIME_FORMAT_DETECTIONS_CACHE)}h` : undefined

    return {
        url: buildURLWithQueryParams(`/${eyed}/v1/detections`, from, to),
        queryKey: ["detections", eyed, fromCachingKey, toCachingKey],
    }
}

export const getDetectionsV2Endpoint = (
    eyed: string,
    from?: string,
    to?: string,
    searchQuery?: string,
    sortBy?: IDetectionsFilterOption,
    sortDirection?: "ASC" | "DESC",
    filterSettings?: IDetectionsFilterSettings,
): QueryEndpoint => {
    // To not constantly trigger new fetches ( because of the date range that's being passed, we create caching keys per hour.)
    const fromCachingKey = from ? `${formatDate(from, DATE_FORMATS.DATE_TIME_FORMAT_DETECTIONS_CACHE)}h` : undefined
    const toCachingKey = to ? `${formatDate(to, DATE_FORMATS.DATE_TIME_FORMAT_DETECTIONS_CACHE)}h` : undefined

    const resolutionStatusTags = filterSettings
        ? translateFilterSettingsToResolutionStatusTags(filterSettings)
        : undefined

    const baseUrl = `${buildURLWithQueryParams(`/${eyed}/v2/detections`, from, to, searchQuery, sortBy, sortDirection, resolutionStatusTags)}`

    return {
        url: baseUrl,
        queryKey: [
            "detections",
            "v2",
            eyed,
            fromCachingKey,
            toCachingKey,
            searchQuery,
            sortBy,
            sortDirection,
            resolutionStatusTags,
        ],
    }
}

export const getDetectionByIdV2Endpoint = (eyed: string, id?: string): QueryEndpoint => {
    const baseUrl = `${buildURLWithQueryParams(`/${eyed}/v2/detections/${id}`)}`

    return {
        url: baseUrl,
        queryKey: ["detections", "byId", "v2", eyed, id],
    }
}

export const getDetectionsV2StatisticsEndpoint = (eyed: string): QueryEndpoint => {
    return {
        url: buildURLWithQueryParams(`/${eyed}/v2/detections/statistics`),
        queryKey: ["detections", "vs", "statistics", eyed],
    }
}

export const getRecommendationsEndpoint = (
    eyed: string,
    language: string,
    riskStagingEnabled: boolean,
): QueryEndpoint => {
    if (riskStagingEnabled) {
        return {
            url: `/${eyed}/v2/recommendations/${language}?version=staging`,
            queryKey: ["recommendations", eyed, language, riskStagingEnabled],
        }
    }

    return {
        url: `/${eyed}/v2/recommendations/${language}`,
        queryKey: ["recommendations", eyed, language, riskStagingEnabled],
    }
}

export const getDashboardMetricsEndpoint = (eyed: string, riskStagingEnabled: boolean): QueryEndpoint => {
    if (riskStagingEnabled) {
        return {
            url: `/${eyed}/v3/dashboard?version=staging`,
            queryKey: ["dashboard-metrics", eyed, riskStagingEnabled],
        }
    }

    return { url: `/${eyed}/v3/dashboard`, queryKey: ["dashboard-metrics", eyed, riskStagingEnabled] }
}

export const getChargebeeSessionEndpoint = (eyed: string): QueryEndpoint => {
    return { url: `/${eyed}/v1/chargebee/portalsession`, queryKey: ["chargebee", eyed, "session"] }
}

export const getUserInformationEndpoint = (): QueryEndpoint => {
    return { url: "/v1/users/me", queryKey: ["users", "me", "information"] }
}

export const getUserOrganisationsEndpoint = (): QueryEndpoint => {
    return { url: "/v1/users/me/organisations", queryKey: ["userOrganisations"] }
}

export const getUserSettingsEndpoint = (): QueryEndpoint => {
    return { url: "/v1/users/me/settings", queryKey: ["userSettings"] }
}

export const getAdminRiskLocalesEndpoint = (): QueryEndpoint => {
    return { url: "/v1/risk_locales", queryKey: ["riskLocales"] }
}

export const getAwarenessRecipientsEndpoint = (eyed: string): QueryEndpoint => {
    return { url: `/${eyed}/v1/awareness/recipients`, queryKey: ["recipients", eyed] }
}

export const getAwarenessMetricsEndpoint = (eyed: string): QueryEndpoint => {
    return { url: `/${eyed}/v1/awareness/metrics`, queryKey: ["awareness-metrics", eyed] }
}

export const getCurrentMonthReportEndpoint = (eyed: string, language: string): QueryEndpoint => {
    return {
        url: `/${eyed}/v1/reporting/download/${language}/current`,
        queryKey: ["management-report", "current", eyed, language],
    }
}

export const getScheduledManagementReportEndpoint = (eyed: string, language: string, date: string): QueryEndpoint => {
    return {
        url: `/${eyed}/v1/reporting/download/${language}/${date}`,
        queryKey: ["management-report", "scheduled", eyed, date, language],
    }
}

export const getManagementReportListEndpoint = (eyed: string): QueryEndpoint => {
    return {
        url: `/${eyed}/v1/reporting/monthly/list`,
        queryKey: ["management-report", "monthly", eyed],
    }
}

export const getAwarenessEventsEndpoint = (
    eyed: string,
    selectedMonth: string,
    type: IAwarenessEvent["type"],
    lastKey?: string,
): QueryEndpoint => {
    return {
        url: `/${eyed}/v1/awareness/events/${type}?period=${selectedMonth}${lastKey ? `&last_key=${lastKey}` : ""}`,
        queryKey: ["awareness-events", eyed, selectedMonth, lastKey, type],
    }
}

export const getAwarenessLanguagesEndpoint = (): QueryEndpoint => {
    return {
        url: `/v1/awareness/languages`,
        queryKey: ["awareness-languages"],
    }
}

export const getAwarenessUpsellMessageEndpoint = (eyed: string): QueryEndpoint => {
    return { url: `/${eyed}/v1/awareness/info`, queryKey: ["awareness-upsell", eyed] }
}

export const getThreatHuntsEndpoint = (eyed: string): QueryEndpoint => {
    return { url: `/${eyed}/v1/threat-hunts`, queryKey: ["threat-hunts", eyed] }
}

export const getOrganisationThreatHuntsEndpoint = (eyed: string): QueryEndpoint => {
    return { url: `/${eyed}/v1/threat-hunts/me`, queryKey: ["threat-hunts", "me", eyed] }
}

/**
 *
 * Mutation Endpoints
 *
 */

interface MutationEndpoint {
    url: string
}

export const postUserOrganisationSettingsEndpoint = (eyed: string): MutationEndpoint => {
    return { url: `/${eyed}/v1/users/me/settings` }
}

export const postStartSentinelOnboarding = (eyed: string): MutationEndpoint => {
    return {
        url: `/${eyed}/v1/users/me/organisations/start-sentinel-onboarding`,
    }
}

export const patchAwarenessRecipientEndpoint = (eyed: string, recipientId: string): MutationEndpoint => {
    return { url: `/${eyed}/v1/awareness/recipients/${recipientId}` }
}

export const getFeedbackEndpoint = (): MutationEndpoint => {
    return { url: "/v1/feedback" }
}

export const postAwarenessRecipientsEndpoint = (eyed: string): MutationEndpoint => {
    return {
        url: `/${eyed}/v1/awareness/recipients`,
    }
}

export const patchAwarenessStateEndpoint = (eyed: string): MutationEndpoint => {
    return {
        url: `/${eyed}/v1/awareness/state`,
    }
}

export const patchAcknowledgeThreatHunt = (eyed: string, threathunt_id: string): MutationEndpoint => {
    return {
        url: `/${eyed}/v1/threat-hunts/${threathunt_id}`,
    }
}
