import { Button } from "@designSystem/buttons/Button"
import { Typography } from "@designSystem/typography/Typography"
import { getValidDateRange } from "@utils/dateRangeUtils"
import { getCurrentTimestamp, getTimestampDaysAgo } from "@utils/dateUtils"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ArrowLongRightIcon } from "@heroicons/react/24/solid"
import { useNavigate } from "react-router-dom"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { MetricBlockSkeleton } from "@designSystem/feedback/skeletons/MetricBlockSkeleton"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import AwaitingDataIllustration from "@assets/illustrations/awaitingDataIllustration.svg?react"
import { MetricBlockFooter, MetricBlockWrapper } from "../metricBlock/MetricBlock"
import { WidgetSize } from "@features/dashboard/dashboardTypes"
import { useDetectionsV2 } from "@features/detectionsv2/hooks/useDetectionsV2"
import { DetectionCard } from "@features/detectionsv2/components/DetectionCard"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { useDetectionsV2Statistics } from "@features/detectionsv2/hooks/useDetectionsV2Statistics"
import { DetectionsCategoryChart } from "@features/detectionsv2/components/DetectionsCategoryChart"
import { getResolutionStatusCategoriesCount } from "@utils/detectionUtils"

interface Props {
    eyed: string
    size?: WidgetSize
}

export const DetectionsV2Widget: React.FC<Props> = ({ eyed, size = "full" }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const activeEyed = useActiveEyed()
    const defaultRange = useMemo(() => {
        return getValidDateRange({
            from: getTimestampDaysAgo(30),
            to: getCurrentTimestamp(),
        })
    }, [])

    const { isPending, data } = useDetectionsV2(eyed, defaultRange, undefined, undefined, "DESC")
    const { data: statisticsData } = useDetectionsV2Statistics(activeEyed)
    const detectionsData = data?.data ?? []
    const detectionsToShow = detectionsData.slice(0, 4)
    const detectionsByResolutionStatus = statisticsData?.data?.count_detections_by_resolution_status_last_30_days
    const resolutionStatusCategories = getResolutionStatusCategoriesCount(detectionsByResolutionStatus)

    if (isPending) {
        return <MetricBlockSkeleton />
    }

    if (detectionsToShow.length === 0) {
        return (
            <MetricBlockWrapper testId="detections-widget">
                <div className="p-6">
                    <Typography variant="body-2-semibold" color="text-primary">
                        {t(`dashboard.metrics.detections.heading`)}
                    </Typography>
                    <div className="py-12">
                        <NoDataMessage
                            title={t("detections:dashboardWidget.noDataTitle")}
                            subtitle={t("detections:dashboardWidget.noDataDescription")}
                            variant="row"
                            image={<AwaitingDataIllustration className="h-[250px]" />}
                        />
                    </div>
                </div>
            </MetricBlockWrapper>
        )
    }

    return (
        <MetricBlockWrapper testId="detections-widget" className="overflow-auto">
            <div className="flex divide-x">
                <div className="flex-1">
                    <div>
                        <div className="p-6 flex justify-between items-center flex-col xl:flex-row">
                            <Typography variant="body-1-semibold" color="text-primary">
                                {t(`detections:dashboardWidget.title`)}
                            </Typography>
                        </div>

                        <div className="px-6 space-y-2" data-testid="detections-widget-cards-list">
                            {detectionsToShow.map((detection) => {
                                return (
                                    <DetectionCard
                                        key={`detection-widget-row-${detection.id}`}
                                        isCardClickable={true}
                                        detection={detection}
                                        onViewDetails={() => {
                                            navigate({
                                                pathname: getRouterLinks().detections.root({ eyed: activeEyed }),
                                                search: new URLSearchParams({ detectionId: detection.id }).toString(),
                                            })
                                        }}
                                    />
                                )
                            })}
                        </div>
                    </div>

                    <MetricBlockFooter withBorder={false}>
                        <div className="pt-2">
                            <Button
                                variant="text"
                                size="small"
                                onClick={() => {
                                    navigate(getRouterLinks().detections.root({ eyed }))
                                }}
                                endIcon={<ArrowLongRightIcon width={16} height={16} />}
                            >
                                {t(`dashboard.metrics.detections.link`)}
                            </Button>
                        </div>
                    </MetricBlockFooter>
                </div>

                {size === "full" && (
                    <div className="w-[340px] p-6 flex-col hidden lg:flex">
                        <div>
                            <Typography variant="body-1-semibold">
                                {t("detections:dashboardWidget.chartTitle")}
                            </Typography>
                            <Typography variant="body-3">{t("detections:dashboardWidget.chartSubtitle")}</Typography>
                        </div>
                        <div className="flex-1 flex items-center justify-center">
                            <DetectionsCategoryChart
                                size="large"
                                detectionsLast30Days={statisticsData?.data?.count_detections_last_30_days ?? 0}
                                resolutionStatusCategories={resolutionStatusCategories}
                            />
                        </div>
                    </div>
                )}
            </div>
        </MetricBlockWrapper>
    )
}
