import { PageHeader } from "@components/header/PageHeader"
import { DetailPageLayout } from "@components/layout/PageLayout"
import React from "react"
import { useTranslation } from "react-i18next"
import HelpdeskIllustration from "@assets/illustrations/helpdeskIllustration.svg?react"
import { Typography } from "@designSystem/typography/Typography"
import { Button } from "@designSystem/buttons/Button"
import { StepText } from "@components/stepText/StepText"

export const NoDetectionsWalkthrough: React.FC = () => {
    const { t } = useTranslation()

    return (
        <DetailPageLayout>
            <PageHeader title={t("detections.title")} />

            <div className="space-y-6 max-w-[1400px]">
                <div className="card p-6 lg:p-16 flex-col lg:flex-row flex items-center gap-6 lg:gap-16">
                    <HelpdeskIllustration className="w-full lg:w-[350px]" />

                    <div className="flex flex-col gap-2 max-w-[450px]">
                        <Typography variant="header-2">{t("detections:noDetectionsWalkthrough.title")}</Typography>
                        <Typography variant="body-1" color="text-secondary">
                            {t("detections:noDetectionsWalkthrough.explainer")}
                        </Typography>
                    </div>
                </div>

                <div className="card p-6 lg:p-12">
                    <Typography variant="header-4">{t("detections:noDetectionsWalkthrough.stepsHeader")}</Typography>
                    <div className="flex mt-6 justify-between *:max-w-[350px] flex-wrap gap-6">
                        <StepText
                            size="large"
                            number={1}
                            title={t("detections:walkthrough.stepsSection.step1.title")}
                            text={t("detections:walkthrough.stepsSection.step1.text")}
                        />
                        <StepText
                            size="large"
                            number={2}
                            title={t("detections:walkthrough.stepsSection.step2.title")}
                            text={t("detections:walkthrough.stepsSection.step2.text")}
                        />
                        <StepText
                            size="large"
                            number={3}
                            title={t("detections:walkthrough.stepsSection.step3.title")}
                            text={t("detections:walkthrough.stepsSection.step3.text")}
                        />
                    </div>
                </div>

                <div className="flex flex-col items-center gap-2 pt-6">
                    <Typography variant="header-4">{t("detections:noDetectionsWalkthrough.moreQuestions")}</Typography>
                    <Typography variant="body-1" className="max-w-[500px] text-center">
                        {t("detections:noDetectionsWalkthrough.helpCenterExplainer")}
                    </Typography>
                    <div className="mt-4">
                        <a href="https://kb.eye.security/resolved-detections" target="_blank" rel="noopener noreferrer">
                            <Button variant="secondary" size="small">
                                {t("detections:noDetectionsWalkthrough.helpCenterButton")}
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </DetailPageLayout>
    )
}
