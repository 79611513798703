import React, { useState } from "react"
import { Typography } from "../typography/Typography"
import { Field, Checkbox as HeadlessCheckbox, Label } from "@headlessui/react"

interface Props {
    label: string
    onCheck: (checked: boolean) => void
    initialValue?: boolean
}

export const Checkbox: React.FC<Props> = ({ label, onCheck, initialValue = false }) => {
    const [checked, setChecked] = useState(initialValue)

    const onChange = (checked: boolean) => {
        onCheck(checked)
        setChecked(checked)
    }

    return (
        <Field className="flex items-center gap-2">
            <HeadlessCheckbox
                checked={checked}
                onChange={onChange}
                className="group block size-5 rounded border bg-white data-[checked]:bg-blue-500 cursor-pointer"
            >
                {/* Checkmark icon */}
                <svg
                    className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </HeadlessCheckbox>
            <Label>
                <Typography variant="body-2">{label}</Typography>
            </Label>
        </Field>
    )
}
