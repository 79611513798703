import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import Avatar from "../../components/designSystem/layout/Avatar"
import { Typography } from "@designSystem/typography/Typography"
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react"
import { AccountSettings } from "./AccountSettings"
import { NotificationSettings } from "./NotificationSettings"
import { useTranslation } from "react-i18next"
import Modal from "@designSystem/overlays/modal/Modal"

const tabStyle = "ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-2"

interface Props {
    active: boolean
    setActive: (active: boolean) => void
}

const UserMenu: React.FC<Props> = ({ active, setActive }) => {
    const { user } = useAuth0()
    const { t } = useTranslation(["common"])

    return (
        <Modal open={active} setOpen={setActive} withCloseButton={true}>
            <div className="w-[325px] sm:w-[600px] md:w-[800px] h-[60vh] overflow-auto flex flex-col">
                <div className="p-6 border-b flex justify-between items-center">
                    <div className="flex items-center gap-4 overflow-hidden">
                        <div>
                            <Avatar
                                testid="usermenu-avatar-menu"
                                name={user?.name ?? ""}
                                size="md"
                                onClick={() => {
                                    setActive(false)
                                }}
                            />
                        </div>

                        <Typography variant="header-3" textElipsis shouldPreventWrapping>
                            {user?.name}
                        </Typography>
                    </div>
                </div>

                <TabGroup>
                    <TabList className="py-4 px-4 border-b flex gap-4">
                        <Tab className={tabStyle}>
                            {({ selected }) => {
                                return (
                                    <Typography
                                        variant={selected ? "body-1-semibold" : "body-1"}
                                        color={selected ? "text-primary" : "text-secondary"}
                                    >
                                        {t("common:userMenu.account.account")}
                                    </Typography>
                                )
                            }}
                        </Tab>
                        <Tab className={tabStyle}>
                            {({ selected }) => {
                                return (
                                    <Typography
                                        variant={selected ? "body-1-semibold" : "body-1"}
                                        color={selected ? "text-primary" : "text-secondary"}
                                    >
                                        {t("common:userMenu.notifications.notifications")}
                                    </Typography>
                                )
                            }}
                        </Tab>
                    </TabList>
                    <TabPanels className={"overflow-auto z-20 flex-1"}>
                        <TabPanel>
                            <AccountSettings />
                        </TabPanel>
                        <TabPanel>
                            <NotificationSettings />
                        </TabPanel>
                    </TabPanels>
                </TabGroup>
            </div>
        </Modal>
    )
}

export default UserMenu
