import DropDown from "@designSystem/overlays/Dropdown/Dropdown"
import { Typography } from "@designSystem/typography/Typography"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { DEFAULT_DATE_RANGES, DefaultRange, IDateRange, getSpecifiedDateRange } from "@utils/dateRangeUtils"

import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
    rangeOption: DefaultRange
    onChange: (range: IDateRange | undefined, option: DefaultRange) => void
}

export const DateRangeOptions: React.FC<Props> = ({ rangeOption, onChange }) => {
    const { t } = useTranslation(["common"])

    const handleChange = (defaultRange: DefaultRange) => {
        onChange(getSpecifiedDateRange(defaultRange), defaultRange)
    }

    const rangeOptions = [
        {
            label: t("common:dateRangePicker.options.today"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.TODAY)
            },
        },
        {
            label: t("common:dateRangePicker.options.last7Days"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.LAST_7_DAYS)
            },
        },
        {
            label: t("common:dateRangePicker.options.thisWeek"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.THIS_WEEK)
            },
        },
        {
            label: t("common:dateRangePicker.options.last30Days"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.LAST_30_DAYS)
            },
        },
        {
            label: t("common:dateRangePicker.options.thisMonth"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.THIS_MONTH)
            },
        },
        {
            label: t("common:dateRangePicker.options.thisYear"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.THIS_YEAR)
            },
        },
        {
            label: t("common:dateRangePicker.options.allTime"),
            onClick: () => {
                handleChange(DEFAULT_DATE_RANGES.ALL_TIME)
            },
        },
    ]

    return (
        <DropDown
            button={
                <div className="h-[36px] border-0 rounded-none w-[140px] flex items-center justify-between px-4">
                    <Typography variant="body-2">{t(`common:dateRangePicker.options.${rangeOption}`)}</Typography>
                    <ChevronDownIcon height={16} width={16} className="text-text-secondary-light" />
                </div>
            }
        >
            {(closePanel) => {
                return (
                    <div className="px-2 py-3 border rounded-md space-y-2">
                        {rangeOptions.map((rangeOption) => {
                            return (
                                <div
                                    key={`range-option-${rangeOption.label}`}
                                    onClick={() => {
                                        rangeOption.onClick()
                                        closePanel()
                                    }}
                                    className="py-1 px-4 hover:bg-brand-eye-orange hover:text-text-primary-dark hover:cursor-pointer hover:text-invert rounded-md"
                                >
                                    <Typography variant="body-2" color="inherit">
                                        {rangeOption.label}
                                    </Typography>
                                </div>
                            )
                        })}
                    </div>
                )
            }}
        </DropDown>
    )
}
