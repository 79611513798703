export const LOCAL_STORAGE_ACTIVE_EYED_KEY = "ACTIVE_EYED"
export const LOCAL_STORAGE_CLOSED_EAST_NOTIFICATION = "CLOSED_EAST_NOTIFICATION"
export const LOCAL_STORAGE_HOTSPOT_PREFIX = "HOTSPOT_VISITED_"
export const LOCAL_STORAGE_SKIP_ACKNOWLEDGEMENT_CONFIRMATION = "SKIP_ACKNOWLEDGEMENT_CONFIRMATION"
export const LOCAL_STORAGE_VISITED_THREAT_HUNTING_INTRODUCTION = "VISITED_THREAT_HUNTING_INTRODUCTION"
export const LOCAL_STORAGE_COMPLETED_INSURANCE_UPSELL = "COMPLETED_INSURANCE_UPSELL"
export const LOCAL_STORAGE_REVIEWED_MS_TENANTS = "REVIEWED_MS_TENANTS"
export const LOCAL_STORAGE_ACTIVE_DETECTIONS_DISPLAY = "ACTIVE_DETECTIONS_DISPLAY"
export const LOCAL_STORAGE_VISITED_DETECTIONS_KEY = "VISITED_DETECTIONS_V2"

const setHasClosedEastNotification = () => {
    localStorage.setItem(LOCAL_STORAGE_CLOSED_EAST_NOTIFICATION, "true")
}

const getHasClosedEastNotification = (): boolean => {
    const hasClosedEastNotification = localStorage.getItem(LOCAL_STORAGE_CLOSED_EAST_NOTIFICATION)

    if (hasClosedEastNotification === "true") {
        return true
    }

    return false
}

const setHotspotVisited = (hotspotId: string) => {
    localStorage.setItem(LOCAL_STORAGE_HOTSPOT_PREFIX + hotspotId, "true")
}

const getHotspotVisited = (hotspotId: string): boolean => {
    const hotspotVisited = localStorage.getItem(LOCAL_STORAGE_HOTSPOT_PREFIX + hotspotId)

    return hotspotVisited === "true"
}

const getSkipAcknowledgeConfirmation = (): boolean => {
    const skipAcknowledgeConfirmation = localStorage.getItem(LOCAL_STORAGE_SKIP_ACKNOWLEDGEMENT_CONFIRMATION)

    return skipAcknowledgeConfirmation === "true"
}

const setSkipAcknowledgeConfirmation = (skip: boolean) => {
    localStorage.setItem(LOCAL_STORAGE_SKIP_ACKNOWLEDGEMENT_CONFIRMATION, `${skip}`)
}

const getHasVisitedThreatHuntingIntroduction = (): boolean => {
    const visitedIntroduction = localStorage.getItem(LOCAL_STORAGE_VISITED_THREAT_HUNTING_INTRODUCTION)

    return visitedIntroduction === "true"
}

const setHasVisitedThreatHuntingIntroduction = () => {
    localStorage.setItem(LOCAL_STORAGE_VISITED_THREAT_HUNTING_INTRODUCTION, "true")
}

const getHasCompletedInsuranceUpsell = (): boolean => {
    const visitedIntroduction = localStorage.getItem(LOCAL_STORAGE_COMPLETED_INSURANCE_UPSELL)

    return visitedIntroduction === "true"
}

const setHasCompletedInsuranceUpsell = () => {
    localStorage.setItem(LOCAL_STORAGE_COMPLETED_INSURANCE_UPSELL, "true")
}

const getHasReviewedMSTenants = (): boolean => {
    const reviewedTenants = localStorage.getItem(LOCAL_STORAGE_REVIEWED_MS_TENANTS)

    return reviewedTenants === "true"
}

const setHasReviewedMsTenants = () => {
    localStorage.setItem(LOCAL_STORAGE_REVIEWED_MS_TENANTS, "true")
}

const getActiveDetectionsDisplay = (): "cards" | "tables" | null => {
    const activeDisplayType = localStorage.getItem(LOCAL_STORAGE_ACTIVE_DETECTIONS_DISPLAY)

    if (activeDisplayType === "cards" || activeDisplayType === "tables") {
        return activeDisplayType
    }

    return null
}

const setActiveDetectionsDisplay = (displayType: "cards" | "tables") => {
    localStorage.setItem(LOCAL_STORAGE_ACTIVE_DETECTIONS_DISPLAY, displayType)
}

const setHasVisitedDetections = () => {
    localStorage.setItem(LOCAL_STORAGE_VISITED_DETECTIONS_KEY, "true")
}

const getHasVisitedDetections = (): boolean => {
    const hasVisited = localStorage.getItem(LOCAL_STORAGE_VISITED_DETECTIONS_KEY)

    if (hasVisited === "true") {
        return true
    }

    return false
}
export const localStorageSettings = {
    getHasVisitedThreatHuntingIntroduction,
    setHasVisitedThreatHuntingIntroduction,
    getSkipAcknowledgeConfirmation,
    setSkipAcknowledgeConfirmation,
    getHasClosedEastNotification,
    setHasClosedEastNotification,
    getHotspotVisited,
    setHotspotVisited,
    getHasCompletedInsuranceUpsell,
    setHasCompletedInsuranceUpsell,
    getHasReviewedMSTenants,
    setHasReviewedMsTenants,
    getActiveDetectionsDisplay,
    setActiveDetectionsDisplay,
    setHasVisitedDetections,
    getHasVisitedDetections,
}
