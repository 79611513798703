import { Button } from "@designSystem/buttons/Button"
import { Step, SteppedFlow } from "@designSystem/steppedFlow/SteppedFlow"
import React, { useState } from "react"
import { RecipientsStep } from "./RecipientsStep"
import { ReportingButtonStep } from "./ReportingButtonStep"
import { IntroductionStep } from "./IntroductionStep"
import { ArrowLongRightIcon, ArrowLongLeftIcon } from "@heroicons/react/24/solid"
import { useTranslation } from "react-i18next"
import { PageLayout } from "@components/layout/PageLayout"
import { DataProcessorStep } from "./DataProcessorStep"
import { LanguageSettingsStep } from "./LanguageSettingsStep"
import { usePatchAwarenessState } from "../hooks/usePatchAwarenessState"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { IAwarenessLanguage } from "../awarenessTypes"
import { useAwarenessLanguages } from "../hooks/useAwarenessLanguages"
import { SteppedFlowSkeleton } from "@designSystem/feedback/skeletons/SteppedFlowSkeleton"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import ErrorIllustration from "@assets/illustrations/powercordIllustration.svg?react"
import { useAwarenessState } from "@features/awareness/hooks/useAwarenessState"
import Modal from "@designSystem/overlays/modal/Modal"
import ContactIllustration from "@/assets/illustrations/contactIllustration.svg?react"
import { Typography } from "@designSystem/typography/Typography"
import { toastService } from "@/services/toastService"

export const OnboardingFlow: React.FC = () => {
    const { t } = useTranslation()
    const arrowRightIcon = <ArrowLongRightIcon className="mt-0.5" width={20} />
    const arrowLeftIcon = <ArrowLongLeftIcon className="mt-0.5" width={20} />
    const activeEyed = useActiveEyed()
    const { data: awarenessLanguages, isPending, isError } = useAwarenessLanguages()
    const { awarenessStep, defaultLanguage } = useAwarenessState()
    const [selectedLanguage, setSelectedLanguage] = useState<IAwarenessLanguage>()
    const fallbackLanguage = awarenessLanguages?.data ? awarenessLanguages.data[0] : undefined
    const { mutate, isPending: isPendingAwarenessStatePatch } = usePatchAwarenessState()
    const [isFinaliseOnboardingModalActive, setIsFinaliseOnboardingModalActive] = useState(false)

    const onChangeStep = (_currentStep: number, updatedStep: number) => {
        mutate({ eyed: activeEyed, update: { step: updatedStep } })
    }

    const onFinished = () => {
        setIsFinaliseOnboardingModalActive(true)
    }

    const finaliseOnboardingFlow = () => {
        // Updates the onbarding state to completed, and also sets the default language that was selected in the previous steps.
        const languageToUpdate = selectedLanguage
            ? selectedLanguage
            : defaultLanguage
              ? defaultLanguage
              : fallbackLanguage
        // After mutating the parent component will have it's state updated after the organisations get refetched.
        // When the parent component state is set to onboarding completed, it will trigger a redirect away from this page.
        mutate(
            { eyed: activeEyed, update: { completed: true, default_language: languageToUpdate } },
            {
                onError: () => {
                    toastService.errorMessage(t("toastMessages:awarenessOnboarding.error"))
                },
                onSuccess: () => {
                    toastService.successMessage(t("toastMessages:awarenessOnboarding.success"))
                },
            },
        )
    }

    const steps: Step[] = [
        {
            title: t("awareness.onboarding.steps.introduction"),
            subtitle: t("awareness.onboarding.steps.step1"),
            content: <IntroductionStep />,
            actions: (onNextStep) => {
                return (
                    <>
                        <Button variant="primary" onClick={onNextStep} endIcon={arrowRightIcon}>
                            {t("awareness.onboarding.steps.nextStep")}
                        </Button>
                    </>
                )
            },
        },
        {
            title: t("awareness.onboarding.steps.addReportingButton"),
            subtitle: t("awareness.onboarding.steps.step2"),
            content: <ReportingButtonStep />,
            actions: (onNextStep, onPreviousStep) => {
                return (
                    <>
                        <Button variant="secondary" onClick={onPreviousStep} startIcon={arrowLeftIcon}>
                            {t("awareness.onboarding.steps.previousStep")}
                        </Button>
                        <Button variant="primary" onClick={onNextStep} endIcon={arrowRightIcon}>
                            {t("awareness.onboarding.steps.nextStep")}
                        </Button>
                    </>
                )
            },
        },
        {
            title: t("awareness.onboarding.steps.includingRecipients"),
            subtitle: t("awareness.onboarding.steps.step3"),
            content: <RecipientsStep />,
            actions: (onNextStep, onPreviousStep) => {
                return (
                    <>
                        <Button variant="secondary" onClick={onPreviousStep} startIcon={arrowLeftIcon}>
                            {t("awareness.onboarding.steps.previousStep")}
                        </Button>
                        <Button variant="primary" onClick={onNextStep} endIcon={arrowRightIcon}>
                            {t("awareness.onboarding.steps.nextStep")}
                        </Button>
                    </>
                )
            },
        },
        {
            title: t("awareness.onboarding.steps.languageSettings"),
            subtitle: t("awareness.onboarding.steps.step4"),
            content: (
                // todo: verify with @bas.meijer to confirm the fix
                <LanguageSettingsStep
                    selectedLanguage={selectedLanguage ? selectedLanguage : (defaultLanguage ?? undefined)}
                    setSelectedLanguage={setSelectedLanguage}
                />
            ),
            actions: (onNextStep, onPreviousStep) => {
                return (
                    <>
                        <Button variant="secondary" onClick={onPreviousStep} startIcon={arrowLeftIcon}>
                            {t("awareness.onboarding.steps.previousStep")}
                        </Button>
                        <Button variant="primary" onClick={onNextStep} endIcon={arrowRightIcon}>
                            {t("awareness.onboarding.steps.nextStep")}
                        </Button>
                    </>
                )
            },
        },
        {
            title: t("awareness.onboarding.steps.dataProcessor"),
            subtitle: t("awareness.onboarding.steps.step5"),
            content: <DataProcessorStep />,
            actions: (onNextStep, onPreviousStep) => {
                return (
                    <>
                        <Button variant="secondary" onClick={onPreviousStep} startIcon={arrowLeftIcon}>
                            {t("awareness.onboarding.steps.previousStep")}
                        </Button>
                        <Button variant="primary" onClick={onNextStep} endIcon={arrowRightIcon}>
                            {t("awareness.onboarding.steps.finishOnboarding")}
                        </Button>
                    </>
                )
            },
        },
    ]

    return (
        <PageLayout variant="emptyPage">
            {isError && (
                <div className="p-12">
                    <NoDataMessage
                        title={t("awareness.onboarding.error.title")}
                        subtitle={t("awareness.onboarding.error.description")}
                        variant="row"
                        image={<ErrorIllustration className="w-[250px]" />}
                    />
                </div>
            )}

            {isPending && <SteppedFlowSkeleton />}

            {isFinaliseOnboardingModalActive && (
                <Modal
                    open={isFinaliseOnboardingModalActive}
                    setOpen={setIsFinaliseOnboardingModalActive}
                    className="p-10"
                    withCloseButton
                >
                    <div className="w-[400px]">
                        <div className="flex justify-center mb-4">
                            <ContactIllustration />
                        </div>

                        <Typography variant="header-2">{t("awareness.onboarding.finalise.modal.title")}</Typography>
                        <Typography className="mt-4">{t("awareness.onboarding.finalise.modal.description")}</Typography>

                        <div className="mt-6 flex gap-3 justify-center">
                            <Button
                                variant="primary"
                                isLoading={isPendingAwarenessStatePatch}
                                disabled={isPendingAwarenessStatePatch}
                                onClick={() => {
                                    finaliseOnboardingFlow()
                                }}
                            >
                                {t("awareness.onboarding.finalise.modal.finishButton")}
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setIsFinaliseOnboardingModalActive(false)
                                }}
                            >
                                {t("awareness.onboarding.finalise.modal.cancel")}
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}

            {!isPending && !isError && (
                <SteppedFlow
                    initialStepIndex={awarenessStep ?? 0}
                    steps={steps}
                    onChangeStep={onChangeStep}
                    onFinished={onFinished}
                />
            )}
        </PageLayout>
    )
}
