import React, { PropsWithChildren } from "react"
import { Typography } from "../../typography/Typography"
import classNames from "classnames"

interface TableProps {
    className?: string
    background?: string
}

export const TableWrapper: React.FC<PropsWithChildren<TableProps & { isRounded?: boolean }>> = ({
    children,
    className = "",
    isRounded = true,
}) => {
    return (
        <div className={`border ${isRounded ? "rounded-lg" : ""}`}>
            <div className={` ${className}`}>{children}</div>
        </div>
    )
}

export const Table: React.FC<
    PropsWithChildren<TableProps & { isRounded?: boolean; isFixed?: boolean; hasBorder?: boolean; testId?: string }>
> = ({ children, className = "", isRounded = false, isFixed = true, hasBorder = true, testId }) => {
    return (
        <div className={`relative overflow-auto ${isRounded ? " rounded-lg" : ""}`} data-testid={testId}>
            <div className={`${className}`}>
                <table
                    className={`w-full text-left ${hasBorder ? "border-neutral-100" : ""} ${isFixed ? "table-fixed" : "table-auto"}`}
                >
                    {children}
                </table>
            </div>
        </div>
    )
}

export const TableHeader: React.FC<PropsWithChildren<TableProps>> = ({
    children,
    className = "",
    background = "bg-background-page-secondary-light",
}) => {
    return (
        <thead className={`${background} border-b-[1px] border-neutral-100 rounded-xl sticky top-0 z-10  ${className}`}>
            {children}
        </thead>
    )
}

export const TableBody: React.FC<PropsWithChildren<TableProps>> = ({ children, className = "" }) => {
    return <tbody className={`divide-y divide-neutral-100  ${className}`}>{children}</tbody>
}

export const TableRow: React.FC<
    PropsWithChildren<
        TableProps & {
            onClick?: () => void
            height?: number
            isSelected?: boolean
            testId?: string
            isHoverable?: boolean
        }
    >
> = ({ children, className = "", onClick, height, isSelected = false, testId, isHoverable = true }) => {
    return (
        <tr
            data-testid={testId}
            className={classNames([
                className,
                { "hover:bg-neutral-100": isHoverable },
                { "cursor-pointer": onClick !== undefined },
                { "bg-neutral-100": isSelected },
            ])}
            style={{ height: height ? `${height}px` : "unset" }}
            onClick={onClick}
        >
            {children}
        </tr>
    )
}

export const TableHeaderCell: React.FC<PropsWithChildren<TableProps & { width?: number }>> = ({
    children,
    className = "",
    width,
}) => {
    return (
        <th className={`py-3 px-6 ${className}`} style={{ width: width ?? "unset" }}>
            <Typography variant="body-2-semibold" color="text-secondary">
                {children}
            </Typography>
        </th>
    )
}

export const SortableTableHeaderCell: React.FC<PropsWithChildren<TableProps & { width?: number }>> = ({
    children,
    className = "",
    width,
}) => {
    return (
        <th className={`py-3 px-6 box-border ${className}`} style={{ width: width ?? "unset" }}>
            {children}
        </th>
    )
}

export const TableCell: React.FC<PropsWithChildren<TableProps & { width?: number }>> = ({
    children,
    className = "",
    width,
}) => {
    return (
        <td className={`py-3 px-6 box-border ${className}`} style={{ width: width ?? "unset" }}>
            {children}
        </td>
    )
}
