import { Typography } from "@designSystem/typography/Typography"
import React from "react"
import { useTranslation } from "react-i18next"
import { Cell, Pie, PieChart, Tooltip, TooltipProps } from "recharts"

const ChartColors = {
    normal: "#DDDFE5",
    abnormal: "#E46E02",
    malicious: "#BB2543",
}

// Custom chart tooltip which renders on hover in the pie chart.
const CustomTooltip = ({ active, payload }: TooltipProps<string, string>) => {
    const { t } = useTranslation()

    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip bg-brand-eye-blue border rounded-md px-4 py-2 w-[200px]">
                {payload.map((dataSet) => {
                    return (
                        <div className="flex" key={dataSet.name}>
                            <div className="flex items-center gap-2">
                                <div
                                    className="w-[12px] h-[12px] rounded-full"
                                    style={{ background: dataSet.payload.fill }}
                                />

                                <Typography color="text-invert" variant="body-2">
                                    {`${dataSet.value} ${dataSet.name} ${t("detections:historicGraphWidget.label")}`}
                                </Typography>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return null
}

export const DetectionsCategoryChart: React.FC<{
    detectionsLast30Days: number
    size?: "default" | "large"
    resolutionStatusCategories: {
        normal: number
        abnormal: number
        malicious: number
    }
}> = ({ detectionsLast30Days, size = "default", resolutionStatusCategories }) => {
    const { t } = useTranslation()

    const chartSize = size === "default" ? 175 : 225
    const innerRadius = size === "default" ? 55 : 80
    const outerRadius = size === "default" ? 70 : 100
    const labelPlacement = size === "default" ? "top-[60px] left-[60px]" : "top-[85px] left-[85px]"

    const data = [
        { name: "Normal", value: resolutionStatusCategories.normal },
        { name: "Abnormal", value: resolutionStatusCategories.abnormal },
        { name: "Malicious", value: resolutionStatusCategories.malicious },
    ]

    const getChartColor = (name: string) => {
        const colorName = name.toLowerCase() as "normal" | "abnormal" | "malicious"
        if (ChartColors[colorName]) {
            return ChartColors[colorName]
        }

        return "#fff"
    }

    return (
        <div className="relative flex justify-center">
            <div>
                <div className="relative">
                    <PieChart width={chartSize} height={chartSize}>
                        <Pie
                            data={data}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            fill="#8884d8"
                            dataKey="value"
                            startAngle={-270}
                        >
                            {data.map((value, index) => (
                                <Cell key={`cell-${index}`} fill={getChartColor(value.name)} />
                            ))}
                        </Pie>

                        <Tooltip
                            cursor={{ fill: "transparent" }}
                            wrapperStyle={{ zIndex: 1000 }}
                            content={<CustomTooltip />}
                        />
                    </PieChart>
                    <div className={`absolute ${labelPlacement} text-center`}>
                        <Typography variant={size === "default" ? "header-2" : "header-1"}>
                            {detectionsLast30Days}
                        </Typography>
                        <Typography variant="body-3" color="text-secondary">
                            {t("detections:overviewWidget.detections")}
                        </Typography>
                    </div>
                </div>

                <div className={`flex items-center gap-2 pb-2 w-full ${size === "large" ? "mt-4 justify-center" : ""}`}>
                    <div className="flex items-center gap-1">
                        <div className="w-2 h-2 bg-text-critical-light rounded-full"></div>
                        <Typography variant="body-3">{t("detections:overviewWidget.legend.malicious")}</Typography>
                    </div>
                    <div className="flex items-center gap-1">
                        <div className="w-2 h-2 bg-text-warning-light rounded-full"></div>
                        <Typography variant="body-3">{t("detections:overviewWidget.legend.abnormal")}</Typography>
                    </div>
                    <div className="flex items-center gap-1">
                        <div className="w-2 h-2 bg-background-grey-light rounded-full"></div>
                        <Typography variant="body-3">{t("detections:overviewWidget.legend.normal")}</Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}
