import { PageHeader } from "@components/header/PageHeader"
import { PageLayout } from "@components/layout/PageLayout"
import { TextInput } from "@designSystem/inputs/TextInput"
import { Typography } from "@designSystem/typography/Typography"
import { RecipientsTable } from "@features/awareness/recipients/RecipientsTable"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid"
import { useRecipients } from "@features/awareness/hooks/useRecipients"
import { useActiveEyed } from "@hooks/useActiveEyed"
import { RecipientsSkeleton } from "@features/awareness/skeletons/RecipientsSkeleton"
import { IRecipient } from "@features/awareness/awarenessTypes"
import { Button } from "@designSystem/buttons/Button"
import { RecipientsLanguageSettingsModal } from "@features/awareness/recipients/RecipientsLanguageSettingsModal"
import { useAwarenessState } from "@features/awareness/hooks/useAwarenessState"
import { Navigate, generatePath } from "react-router"
import { getRouterLinks } from "@config/routes/routeLinksConfig"
import { NoDataMessage } from "@designSystem/feedback/notifications/NoDataMessage"
import SearchIllustration from "@assets/illustrations/searchIllustration.svg?react"
import { useNavigate } from "react-router-dom"
import { TimeAgoDate } from "@components/dates/TimeAgoDate"

export const AwarenessRecipients: React.FC = () => {
    const { t } = useTranslation()
    const activeEyed = useActiveEyed()
    const { data, isPending } = useRecipients(activeEyed)
    const recipients: IRecipient[] = data?.data ?? []
    const [searchValue, setSearchValue] = useState("")
    const [isEditLanguageModalActive, setIsEditLanguageModalActive] = useState(false)
    const { hasFinishedAwarenessOnboarding, recipientSource } = useAwarenessState()
    const recipientsCount = data?.data?.length ?? 0
    const activeRecipientsCount = data?.data?.filter((recipient) => recipient.isActive).length ?? 0

    const isCSVOrganisationWithoutRecipients = recipientSource === "CSV" && recipientsCount === 0
    const navigate = useNavigate()

    const navigateToSupportPage = () => {
        navigate(getRouterLinks().support({ eyed: activeEyed }))
    }

    if (hasFinishedAwarenessOnboarding === false) {
        // Redirect the user back to the awareness landing page.
        // Users can get to this point through the account switching from a organisation with awareness configured to a org without it configured.
        return <Navigate to={generatePath(getRouterLinks().awareness.root({ eyed: activeEyed }))} />
    }

    if (isPending) {
        return <RecipientsSkeleton />
    }

    if (isCSVOrganisationWithoutRecipients) {
        return (
            <PageLayout variant="detailPage">
                <PageHeader title={t("awareness.recipientInfo.pageTitle")} withBreadcrumbs />

                <NoDataMessage
                    title={t("awareness.recipientInfo.noRecipientsMessage.title")}
                    subtitle={t("awareness.recipientInfo.noRecipientsMessage.description")}
                    variant="column"
                    image={<SearchIllustration className="w-[250px]" />}
                    action={
                        <div className="mt-4 flex justify-center">
                            <Button variant="secondary" size="small" onClick={navigateToSupportPage}>
                                {t("awareness.recipientInfo.noRecipientsMessage.contactSupport")}
                            </Button>
                        </div>
                    }
                />
            </PageLayout>
        )
    }

    return (
        <PageLayout variant="detailPage">
            <PageHeader title={t("awareness.recipientInfo.pageTitle")} withBreadcrumbs />

            <Typography>
                <Trans
                    count={activeRecipientsCount}
                    i18nKey="pages:awareness.recipientInfo.description"
                    components={[
                        <a
                            href="https://kb.eye.security/faq-security-awareness"
                            key="kb-link"
                            className="inline-block text-eye-purple-100"
                            rel="noopener noreferrer"
                            target="_blank"
                        />,
                    ]}
                />
            </Typography>

            <div className="mt-2">
                <TimeAgoDate
                    prefix={t("details.timestamp_updated")}
                    timestamp={data?.meta_data?.last_updated}
                    testId="awareness-events-updated-since"
                />
            </div>

            <RecipientsLanguageSettingsModal
                isEditLanguageModalActive={isEditLanguageModalActive}
                setIsEditLanguageModalActive={setIsEditLanguageModalActive}
            />

            <div className="mt-8">
                <div className="flex justify-between items-center">
                    <TextInput
                        value={searchValue}
                        onChange={(event) => {
                            setSearchValue(event.target.value)
                        }}
                        className="max-w-[325px]"
                        iconLeft={<MagnifyingGlassIcon width={20} />}
                        placeholder={t("awareness.recipientInfo.searchPlaceholder")}
                        data-testid="recipients-search"
                    />

                    <Button variant="secondary" size="small" onClick={() => setIsEditLanguageModalActive(true)}>
                        {t("awareness.recipientInfo.editLanguageButton")}
                    </Button>
                </div>

                <div className="mt-6">
                    <RecipientsTable recipients={recipients} searchValue={searchValue} />
                </div>
            </div>
        </PageLayout>
    )
}
