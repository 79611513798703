import React, { Fragment, useState } from "react"
import FeedbackModal from "../designSystem/overlays/modal/FeedbackModal"
import { useTranslation } from "react-i18next"
import { Typography } from "@designSystem/typography/Typography"

export const FeedbackLabel: React.FC = () => {
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false)
    const { t } = useTranslation(["pages"])

    return (
        <Fragment>
            <div
                // We need this hack to prevent blurry text in the rotated content
                data-testid="feedback-button"
                style={{ transformOrigin: "50% 51%" }}
                className="hidden fixed right-[-33px] top-[350px] bg-brand-eye-blue h-[35px] w-[100px] -rotate-90 md:flex justify-center items-center rounded-t-lg cursor-pointer z-30 hover:bg-brand-eye-blue/90"
                onClick={() => {
                    setIsFeedbackOpen(!isFeedbackOpen)
                }}
            >
                <Typography variant="body-2-semibold" color="text-invert">
                    {t("feedback.button")}
                </Typography>
            </div>
            <FeedbackModal open={isFeedbackOpen} setOpen={() => setIsFeedbackOpen(false)} />
        </Fragment>
    )
}
